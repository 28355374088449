export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/profile.vue'),
    meta: {
      noAuth: true,
      title: 'Staff Bank',
    },
  },
]
