import api from '../axios';

export default {
  getPractices: (payload) => api.get('api/practices'),
  createPractice: (payload) => api.post('api/practices', payload),
  getPracticeList: (payload) => api.get('api/practice-types'),

  //pcn
  getPcnTypes: (payload) => api.get('api/pcn/types'),
  createPcn: (payload) => api.post('api/pcn', payload),
  getPcnList: (payload) => api.get('/api/pcn'),

  //locum
  registerLocum: (payload) => api.post('api/locum/register', payload),
  checkUserIsLocum: (payload) => api.get('api/locum/current'),
  getJobRoleList: (payload) => api.get('api/job-roles?page=1&paginate=49'),
  saveUploadedFileKey: (payload) => api.post('api/locum/files', payload),
};
