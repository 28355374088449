import profile from '@/apis/modules/profile'
import Practice from '@/apis/modules/Practice'
import ErrorMessages from '@/mixins/ErrorMessages'
import Cookies from 'js-cookie'
import router from '@/router/index'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import {mpAppUrl,pcnAppUrl} from '@/constants/config.js'



export default {
  mixins: [ErrorMessages],

  state: {
    current_user_data: null,
    authenticated:false,
  },
  mutations: {
    CLEAR_CURRENT_USER: state => {
      state.current_user_data = undefined
      state.current_user_permission = undefined
        localStorage.removeItem('isLoggedIn')
    },
    SET_CURRENT_USER_PERMISSION: (state, value) => {
      state.current_user_permission = value
    },
    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value
      // Cookies.set('current_user_token', value.token)
    },
    UPDATE_USER_INFO: (state, { displayName, photoURL }) => {
      state.current_user_data.name = displayName
      state.current_user_data.image = photoURL
    },
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
  },
  getters: {
    isLogedIn: state => localStorage.getItem('isLoggedIn'),
    currentUser: state => state.current_user_data,
    permissions: state => state.current_user_permission,
    authenticated:state => state.authenticated

  },
  actions: {
    setCurrentUser: ({ commit }, payload) => { commit('SET_CURRENT_USER', payload) },
    // eslint-disable-next-line no-unused-vars
    // async afterLogin({ commit }, fromLogin) {
    //   if (!fromLogin) await router.replace('/scheduler')
    // },
    async testLogin({ commit }){
      // try {
      //   const cacheUserData = (await profile.getCurrentUser())
      //   commit('SET_AUTHENTICATED', true)
      //   commit('SET_CURRENT_USER', cacheUserData.data)
      // }catch (e){
      //   commit('SET_AUTHENTICATED', false)
      //   commit('SET_CURRENT_USER', null)
      // }
      //
      // // return cacheUserDat.then((response) => {
      // //
      // // }).catch(() => {
      // //
      // // })

    },
    async autoLogin({ commit }) {
      try {

        // if (this.state.current_user_data === null){
          const cacheUserData = (await profile.getCurrentUser()).data.data
          commit('SET_CURRENT_USER', {
            user: cacheUserData,
          })
        sessionStorage.setItem('is_current_user_data', '1')
        // }
      }catch (error){
        console.log(error)
      }
      // try {

      //   // const cacheUserToken = Cookies.get('current_user_token')
      //   // if (!this.getters.isLogedIn && cacheUserToken) {
      //     const cacheUserData = (await profile.getCurrentUser()).data
      //     commit('SET_AUTHENTICATED', true)
      //     commit('SET_CURRENT_USER', {
      //       user: cacheUserData,
      //       // access_token: cacheUserToken,
      //     })
      //     commit('UPDATE_USER_INFO', {
      //       displayName: cacheUserData.name,
      //       photoURL: cacheUserData.photo_url,
      //     })
      //     await this.dispatch('afterLogin', true)
      //   // }
      // } catch (e) {
      //   commit('SET_AUTHENTICATED', false)
      //   // console.log(e)
      //   await this.dispatch('logout')
      // }
    },
    async login({ commit },form) {
      let payload
      if (localStorage.getItem('newUser')){
        payload = {
          email: form.email,
          password: form.old_password,
        }
      }else{
        payload = form
      }
        await profile.cfrs()
        const loginResponse = await profile.login(payload)

        if(loginResponse.status === 204){
          localStorage.removeItem('registered')
          localStorage.setItem('isLoggedIn', 'true')
          const cacheUserData = (await profile.getCurrentUser()).data.data
          commit('SET_CURRENT_USER', {
            user: cacheUserData,
          })
          sessionStorage.setItem('is_current_user_data', '1')
          if (localStorage.getItem('newUser')){
            try{
              let respond = await profile.resetPasswordNewUser(form)
                localStorage.removeItem('newUser')
                if(cacheUserData.practices.length>0){
                  window.location = `${mpAppUrl}/my-shifts?practice_id=${cacheUserData.practices[0].id}`
                }else{
                  window.location = `${pcnAppUrl}/dashboard?auth_pcn_id=${cacheUserData.pcns[0].id}`
                }
            }catch(e){
              await profile.logout()
              localStorage.removeItem('isLoggedIn')
                 
            }

          }else {
              if(cacheUserData.pcns.length + cacheUserData.practices.length === 1){
                if(cacheUserData.practices.length>0){
                  const response = (await Practice.getPractices()).data.data
                  if((response[0].team.roles.some(el =>el.name ==='practice-admin'))){
                    window.location = `${mpAppUrl}/dashboard?practice_id=${cacheUserData.practices[0].id}`
                  }else{
                    window.location = `${mpAppUrl}/my-shifts?practice_id=${cacheUserData.practices[0].id}`
                  }
                }else{
                  window.location = `${pcnAppUrl}/dashboard?auth_pcn_id=${cacheUserData.pcns[0].id}`
                }
              }else{
                window.location = '/dashboard'
              }

          }

        }else {
          localStorage.removeItem('newUser')
          window.location= '/'
        }


      // Cookies.set('current_user_time', new Date().getTime())
      // commit('SET_CURRENT_USER', loginData)
      // commit('UPDATE_USER_INFO', {
      //   displayName: loginData.data.first_name,
      //   photoURL: loginData.data.photo_url,
      // })

      // try {
      //   const cacheUserData = (await profile.getCurrentUser())
      //   commit('SET_AUTHENTICATED', true)
      //   commit('SET_CURRENT_USER', cacheUserData.data)
      // }catch (e){
      //   commit('SET_AUTHENTICATED', false)
      //   commit('SET_CURRENT_USER', null)
      // }

      // await this.dispatch('testLogin')
    },
    async logout({ commit }) {
      try {
        await profile.logout()
        localStorage.removeItem('isLoggedIn')
        if (window.location.pathname !== '/') {
          window.location = '/'
        }

      } catch (error) {
        await commit('CLEAR_CURRENT_USER')
      }
      await commit('CLEAR_CURRENT_USER')
    },
    async updateCurrentUserPhotoUrl({ commit }, newUrl) {
      commit('UPDATE_USER_INFO', {
        photoURL: newUrl,
      })
    },
  },
}
